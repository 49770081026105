<template>
  <div class="sticky-bar">
    <Icon class="top" name="arrow-circle-plain-left" @click="scrollToTop" />
    <a :href="`tel:${phoneNumber}`" @click="handlePhone">
      <div v-if="phoneNumber" class="contact">
        <Icon class="phone" height="20px" name="phone" width="20px" />
        <div v-if="!breakpoints.isMobile" class="text">{{ phoneNumber }}</div>
      </div>
    </a>

    <div v-if="isGir">
      <div class="quotation" @click="closeModal">
        <Icon fill="white" height="28px" name="logo" stroke="white" width="28px" />
        Choisir une date
      </div>
    </div>
    <NuxtLink v-else :href="!isHeroSea && quotationUrl" @click="handleClick">
      <div class="quotation">
        <Icon fill="white" height="28px" name="logo" stroke="white" width="28px" />
        Demander un devis
      </div>
    </NuxtLink>
  </div>
</template>

<script lang="ts" setup>
const { quotationUrl = '/voyage-sur-mesure/', isHeroSea } = defineProps<{
  isGir?: boolean;
  quotationUrl?: string;
  isHeroSea?: boolean;
}>();
const emit = defineEmits(['update:modelValue']);
function closeModal() {
  emit('update:modelValue', true);
}
const phoneNumbersStore = usePhoneNumbersStore();
const phoneNumber = ref<string | undefined>(phoneNumbersStore.phoneNumberDefault);
const route = useRoute();
const breakpoints = useBreakpoints();
const { pushToDataLayer } = useDataLayer();
function scrollToTop(): void {
  window.scrollTo({
    top: 0,
    behavior: 'smooth'
  });
}

function handleClick(event: MouseEvent) {
  if ((route.query.gclid || route.query.msclkid) && isHeroSea) {
    event.preventDefault();
    scrollToTop();
  }
}

onMounted(() => {
  if (route.params.countryid) {
    phoneNumber.value = phoneNumbersStore.getPhoneCountry(route.params.countryid);
  }

  if (route.params.inspirationsslug) {
    if (
      ['nos-voyages-aventure'].includes(route.params.inspirationsslug) &&
      phoneNumbersStore.phoneNumberAventure
    ) {
      phoneNumber.value = phoneNumbersStore.phoneNumberAventure;
    } else if (
      ['voyage-de-noces', 'nos-voyages-de-noces'].includes(route.params.inspirationsslug) &&
      phoneNumbersStore.phoneNumberNoces
    ) {
      phoneNumber.value = phoneNumbersStore.phoneNumberNoces;
    }
  }
});

const handlePhone = () => {
  pushToDataLayer({ event: 'phoneClick', phone: phoneNumber.value });
};
</script>

<style lang="scss" scoped>
@use '$/breakpoints.scss';
@use '$/button.scss';
@use '$/colors.scss';
@use '$/mouse.scss';
@use '$/shadows.scss';
@use '$/z-index.scss';

.sticky-bar {
  position: fixed;
  z-index: z-index.$sticky-panel;
  bottom: 0;
  left: 0;

  gap: 16px;
  justify-content: center;

  width: 100%;
  padding: 14px 0;

  background-color: colors.$primary-yellow;

  @include breakpoints.mobile() {
    padding: 8px 0;
  }

  .top {
    $color: colors.$primary-black;

    @include mouse.clickable-svg(
      $color-base: $color,
      $color-hover: colors.adjust-lightness-smart($color, 12.5%),
      $selector-svg: 'circle'
    );

    position: absolute;
    top: -50px;
    right: 40px;

    overflow: hidden;

    border-radius: 50%;
    box-shadow: shadows.$bubble-map;

    @include breakpoints.mobile() {
      right: 16px;
    }

    &:deep(svg) {
      transform: rotate(90deg);

      circle {
        fill: colors.$black;
      }

      path {
        fill: white;
      }
    }
  }

  .quotation {
    @include button.plain();

    .text {
      @include breakpoints.mobile() {
        display: none;
      }
    }
  }

  .contact {
    @include button.outlined($svg-property: fill);
  }

  .contact,
  .quotation {
    gap: 12px;
    width: 275px;

    @include breakpoints.mobile() {
      width: auto;
      height: 40px;
      padding: 5px 16px 6px;
    }
  }
}
</style>
